import { gql } from '@apollo/client';

export const login = gql`
mutation getToken(
    $username: String!,
    $password: String!,
    $tenantId: String!
) {
    login(username: $username, password: $password, tenantId: $tenantId){
        token
        tenan_id
        brand_id
    }
}
`;

export const forgotPassword = gql`
    mutation forgotPassword(
        $tenantId: String!,
        $email: String!
    ) {
        forgotPassword(tenantId: $tenantId, email: $email)
    }
`;

export const internalDeleteSession = gql`
           mutation internalDeleteSession {
               internalDeleteSession {
                   result
               }
           }
       `;

export const getAdminProfile = gql`
    query getAdminProfile { getAdminProfile {
        accountId
        date_birth
        first_name
        gender
        last_name
        phone
        addresses
    }
}
`;

export const loginAdminByBrandId = gql`
mutation loginByBrandId($key: String!, $tenantId: String!) {
  loginByBrandId(
    key: $key
  tenantId:$tenantId
  ) {
    token
    tenan_id
    brand_id
    admin {
      email
      firstName
      lastName
      phone
      accountId
    }
  }
} 
`;

export default {
    login,
    getAdminProfile,
    forgotPassword,
};
