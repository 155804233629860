import { useMutation, useLazyQuery } from '@apollo/client';
import * as Schema from '@core_modules/authentication/service/graphql/schema';

export const login = (options = {}) => useMutation(Schema.login, {
    context: {},
    ...options,
});

export const deleteSession = () => useMutation(Schema.internalDeleteSession, {
    context: {
        request: 'internal',
    },
});

export const getAdminProfile = (options = {}) => useLazyQuery(Schema.getAdminProfile, {
    ...options,
    context: {
        request: 'internal',
    },
});

export const forgotPassword = (options = {}) => useMutation(Schema.forgotPassword, {
    ...options,
});

export const loginAdminByBrandId = (options = {}) => useMutation(Schema.loginAdminByBrandId, {
    context: {},
    ...options,
});

export default {
    login,
    getAdminProfile,
    forgotPassword,
    loginAdminByBrandId,
};
