import React from 'react';
import Head from 'next/head';
import Content from '@core_modules/authentication/pages/default/components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { login } from '@core_modules/authentication/service/graphql';
import dynamic from 'next/dynamic';
import { setLogin } from '@helper_auth';
import router from 'next/router';
import Cookies from 'js-cookie';
import { keyCookies } from '@config';
import { encrypt } from '@helper_encryption';
import * as service from '@core_modules/authentication/service/graphql';

const Message = dynamic(() => import('@common_toast'), { ssr: false });

const Authentication = (props) => {
    const {
        t,
    } = props;
    const [open, setOpen] = React.useState(true);

    const [actLogin, { loading }] = login({});
    const [toast, setToast] = React.useState({
        open: false,
        variant: 'success',
        text: '',
    });
    const [getAdminProfile, { data: itemAdminProfile }] = service.getAdminProfile();

    // formik
    const formValidate = Yup.object().shape({
        email: Yup.string().required(t('validate:email:required')),
        password: Yup.string().required(t('validate:password:required')),
        tenantId: Yup.string().required(`Tenant ID ${t('validate:required')}`),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            tenantId: '',
        },
        validationSchema: formValidate,
        onSubmit: async (values, { resetForm }) => {
            // submit
            try {
                actLogin({
                    variables: {
                        username: values.email,
                        password: values.password,
                        tenantId: values.tenantId,
                    },
                }).then((res) => {
                    if (res && res.data && res.data.login && res.data.login.token) {
                        const { tenan_id, brand_id, token } = res.data.login;
                        Cookies.set(keyCookies.loginData, {
                            tenan_id: tenan_id || values.tenantId,
                            brand_id,
                            email: values.email,
                        });
                        Cookies.set(keyCookies.token, encrypt(token));
                        Cookies.set(keyCookies.defaultBrandId, brand_id[0]);
                        resetForm();
                        setLogin(1);
                        const options = {
                            context: {
                                headers: {
                                    authorization: token,
                                },
                            },
                            skip: !token || token === '',
                        };
                        const brandId = brand_id[0];
                        getAdminProfile({
                            ...options,
                            skip: !brandId || brandId === '' || !token || token === '',
                        });
                    }
                }).catch(() => {
                    setToast({
                        open: true,
                        variant: 'error',
                        text: t('login:failed'),
                    });
                });
            } catch (error) {
                setToast({
                    open: true,
                    variant: 'error',
                    text: t('login:failed'),
                });
            }
        },
    });

    React.useEffect(() => {
        if (itemAdminProfile && itemAdminProfile.getAdminProfile) {
            Cookies.set(keyCookies.profileName, itemAdminProfile.getAdminProfile.first_name);
            router.push('/profile');
        }
    }, [itemAdminProfile]);

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            const domain = /:\/\/([^\\/]+)/.exec(window.location.href)[1];
            const splitDomain = domain.split('.');
            const mainDomain = domain.split('.');
            mainDomain.splice(0, 1);
            const main = mainDomain.join('.', 2);
            if (main === 'swift-checkout.com' && splitDomain.length > 2) {
                formik.setFieldValue('tenantId', splitDomain[0]);
            }
        }
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseMessage = () => {
        setToast({
            ...toast,
            open: false,
        });
    };

    const propsContent = {
        loading,
    };
    return (
        <>
            <Head>
                <title>{t('common:menu:sign')}</title>
            </Head>
            <Message
                open={toast.open}
                variant={toast.variant}
                setOpen={handleCloseMessage}
                message={toast.text}
            />
            <Content formik={formik} open={open} handleClose={handleClose} t={t} {...propsContent} />
        </>
    );
};

export default Authentication;
