import { withApollo } from '@lib_apollo';
import { withTranslation } from '@i18n';
import Core from '@core_modules/authentication/pages/default/core';

const Page = (props) => <Core {...props} />;

Page.getInitialProps = (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const { req } = ctx;
    // if (!req.query || !req.query.state) {
    //     ctx.res.statusCode = 302;
    //     ctx.res.setHeader('Location', '/');
    //     return { props: {}, namespacesRequired: ['common', 'checkout', 'customer', 'validate'] };
    // }
    return {
        // query: req.query || '',
        namespacesRequired: ['common', 'validate', 'login'],
    };
};

export default withApollo({ ssr: false })(withTranslation()(Page));
