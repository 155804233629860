/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Button from '@common_button';
import TextField from '@common_textfield';
import PasswordField from '@common_password';
import Typography from '@common_typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const AuthView = (props) => {
    const {
        open, handleClose, t, formik, loading,
    } = props;

    return (
        <div className="dialog-auth">
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-auth"
                disableBackdropClick
                disableEscapeKeyDown
                scroll="body"
                PaperProps={{
                    className: 'dialog-auth-paper',
                }}
            >
                <DialogTitle id="dialog-auth">
                    <img src="/assets/img/scv2_logo.svg" alt="" className="auth-logo" />
                </DialogTitle>
                <DialogContent>
                    <div className="auth-model-body">
                        <Typography type="semiBold" variant="h6">
                            {t('common:form:email')}
                        </Typography>
                        <TextField
                            placeholder={`${t('common:label:ex')} sample@gmail.com`}
                            name="email"
                            id="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            error={formik.errors.email}
                            errorMessage={formik.errors.email}
                        />
                        <Typography type="semiBold" variant="h6">
                            {t('common:form:password')}
                        </Typography>
                        <PasswordField
                            placeholder={`${t('common:label:signinPassword')}`}
                            name="password"
                            id="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            error={formik.errors.password}
                            errorMessage={formik.errors.password}
                            showVisible
                        />
                        <Typography type="semiBold" variant="h6">
                            {t('common:form:tenantId')}
                        </Typography>
                        <TextField
                            placeholder=""
                            name="tenantId"
                            id="tenantId"
                            onChange={formik.handleChange}
                            value={formik.values.tenantId}
                            error={formik.errors.tenantId}
                            errorMessage={formik.errors.tenantId}
                            autoComplete="off"
                        />
                        <div className="auth-model-footer">
                            <Button loading={loading} fullWidth onClick={() => formik.handleSubmit()}>
                                <Typography type="semiBold" variant="span" color="white">
                                    {t('common:button:login')}
                                </Typography>
                            </Button>
                            <a href="/register" className="register-btn">
                                <Typography type="semiBold" variant="span" decoration="underline" align="center">
                                    {t('common:button:register')}
                                </Typography>
                            </a>
                            <a href="/forgotpassword">
                                <Typography type="semiBold" variant="span" decoration="underline" align="center">
                                    {t('common:label:forgotPassword')}
                                </Typography>
                            </a>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <style jsx global>
                {`
                    .dialog-auth {
                        background-image: url('/assets/img/bg-auth.png');
                        background-size: cover;
                        width: 100vw;
                        height: 100vh;
                    }

                    .dialog-auth-paper {
                        width: 500px;
                        max-width: 90%;
                    }

                    #dialog-auth {
                        width: 100%;
                        justify-content: center;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-top: 20px;
                    }

                    .auth-logo {
                        width: 150px;
                        height: auto;
                    }

                    .auth-model-footer {
                        margin-top: 30px;
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 90px;
                        widh: 100%;
                        text-align: center;
                    }

                    .register-btn {
                        margin-top: 20px;
                    }

                    .otp-resend-link {
                        font-weight: bold;
                        text-decoration: underline;
                    }

                    .auth-model-body {
                        height: 100%;
                    }

                    .auth-model-body h6 {
                        margin: 0px !important;
                        font-size: 12px;
                    }
                `}
            </style>
        </div>
    );
};

export default AuthView;
